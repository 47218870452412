import './OthersProject.css';
import OthersCard from '../OthersCard';
import SectionTitle from '../SectionTitle';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OthersProject = ({ projectArr }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className='container'>
      <SectionTitle title='Outros projetos' />
      {/* <div className='othersCard-container'>
        {projectArr.map((project) => <OthersCard otherProject={project} />)}
      </div> */}
      <Carousel responsive={responsive}>
        {projectArr.map((project) => <OthersCard otherProject={project} />)}
      </Carousel>
    </div>
  );
};

export default OthersProject;
